import { Button } from '@components/Common';
import { useLocale } from '@components/Contexts/LocaleContext';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import FooterOldDesignStyle from './FooterOldDesignStyle';

interface Props {
  showLetsTalk?: boolean;
  forOldDesign?: boolean;
}

const Footer: FC<Props> = ({ showLetsTalk = false, forOldDesign = false }) => {
  const router = useRouter();
  const { lang } = useLocale();
  return (
    <>
      <footer id="footer" className="w-screen bg-black">
        {showLetsTalk && (
          <div className="Container px-4 py-7 flex flex-col justify-center items-center bg-darkBlue text-white">
            <div className="">
              <Image
                alt="mini-logo-codium"
                src="/images/minilogocodium.svg"
                width={40}
                height={40}
              />
            </div>
            <h1 className="text-h6Medium">LET&apos;S TALK</h1>
            <p className=" text-p3Regular">Tell us more about your project</p>
            <div className="mt-4">
              <Button
                id="contact-us"
                text={lang === 'en' ? 'CONTACT US' : 'ติดต่อเรา'}
                className="font-medium"
                onClick={() => router.push('/contact-us')}
              />
            </div>
          </div>
        )}
        <div className="w-screen bg-black">
          <div className="Container flex flex-col md:flex-row md:justify-between">
            {/* contact us */}
            <div className="flex flex-col text-white items-start my-7">
              <h1 className=" text-p3Medium md:text-p2Medium mb-7">
                {lang === 'en' ? 'CONTACT US' : 'ติดต่อเรา'}
              </h1>

              <div className="flex flex-col gap-5">
                {contacts.map((contact) => (
                  <Link key={contact.id} href={contact.link}>
                    <a className="flex gap-3 items-center cursor-pointer hover:text-neutral-400">
                      <div className="w-[20px] h-[20px]">
                        {contact.icon && (
                          <Image
                            alt={contact.alt}
                            src={contact.icon}
                            width={20}
                            height={20}
                          />
                        )}
                      </div>
                      <span className="text-p3Regular hover:underline">
                        {lang === 'en'
                          ? contact.text
                          : contact?.textTH ?? contact.text}
                      </span>
                    </a>
                  </Link>
                ))}
              </div>
            </div>

            <div className="flex flex-col items-start md:w-[278px] lg:w-[448px]">
              {/* social media for mobile */}
              <div className="md:hidden w-full flex justify-around items-center rounded-md my-[10px] py-2 px-10 bg-[#28272C]">
                {socials.map((social) => (
                  <Link key={social.id} href={social.link}>
                    <a className="flex justify-center items-center cursor-pointer">
                      <Image
                        alt={social.alt}
                        src={social.icon}
                        width={20}
                        height={20}
                      />
                    </a>
                  </Link>
                ))}
              </div>

              {/* address */}
              <div className="flex flex-col mt-7 mb-4 items-start text-white text-p4Regular">
                <p className="text-p4Regular">
                  {lang === 'en' ? (
                    <>
                      SIAM@SIAM Hotel Building <br />
                      7th Floor 865 Rama 1 Road <br />
                      Wang Mai, Patumwan <br />
                      Bangkok 10330 <br />
                      Thailand
                    </>
                  ) : (
                    <>
                      โรงแรม SIAM@SIAM <br />
                      ชั้น 7, 865 ถนน พระราม 1 <br />
                      แขวง วังใหม่, เขต ปทุมวัน <br />
                      กรุงเทพมหานคร 10330
                    </>
                  )}
                </p>

                <p className="text-p4Regular">
                  {lang === 'en'
                    ? 'Office Hours: Monday - Friday (09.00 - 18.00)'
                    : 'เวลาทำการ: จันทร์ - ศุกร์ (09.00 - 18.00)'}
                </p>
              </div>

              {/* social media for tablet & desktop */}
              <div className="hidden md:flex w-full justify-around items-center rounded-md my-[10px] py-2 md:px-4 lg:px-16 bg-[#28272C]">
                {socials.map((social) => (
                  <Link key={social.id} href={social.link}>
                    <a className="flex justify-center items-center cursor-pointer hover:scale-150 ease-in-out duration-200">
                      <Image
                        alt={social.alt}
                        src={social.icon}
                        width={20}
                        height={20}
                      />
                    </a>
                  </Link>
                ))}
              </div>

              {/* PDPA */}
              <div className="flex flex-col mt-4 mb-4 gap-2 items-start text-lightBlue text-p4Regular">
                {pdpas.map((pdpa) => (
                  <Link key={pdpa.link} href={pdpa.link}>
                    <a className="text-p4Regular cursor-pointer hover:text-lightBlue-600 underline">
                      {pdpa[lang]}
                    </a>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* copyright */}
        <div className="w-full bg-neutral-300 flex justify-center items-center py-2">
          <p className=" text-p4Regular text-neutral-500">
            Copyright © 2022 by CODIUM all right reserved
          </p>
        </div>
      </footer>

      {forOldDesign && <FooterOldDesignStyle />}
    </>
  );
};

export default Footer;

const contacts = [
  {
    id: 1,
    alt: 'phone',
    icon: '/icons/iphone.svg',
    text: '02-0982099, Mon - Fri (09.00-18.00)',
    textTH: '02-0982099, จันทร์ - ศุกร์ (09.00-18.00)',
    link: 'tel:02-0982099',
  },
  {
    id: 3,
    alt: 'mail',
    icon: '/icons/mail.svg',
    text: 'sales@codium.co',
    textTH: 'sales@codium.co',
    link: 'mailto:sales@codium.co',
  },
  {
    id: 5,
    alt: 'line',
    icon: '/icons/line.svg',
    text: '@codium',
    link: 'https://line.me/ti/p/%40codium',
  },
];

const socials = [
  {
    id: 1,
    alt: 'facebook',
    icon: '/icons/facebook_circle.svg',
    link: 'https://www.facebook.com/codium/',
  },
  {
    id: 2,
    alt: 'youtube',
    icon: '/icons/youtube_circle.svg',
    link: 'https://www.youtube.com/channel/UCQ7PU-eA4lmdWkN86lYsJUw/videos',
  },
  {
    id: 3,
    alt: 'linkedin',
    icon: '/icons/linkedin_circle.svg',
    link: 'https://th.linkedin.com/company/c0d1um',
  },
  {
    id: 4,
    alt: 'medium',
    icon: '/icons/medium.svg',
    link: 'https://medium.com/c0d1um',
  },
];

const pdpas = [
  {
    link: '/privacy-policy',
    en: 'Privacy Policy',
    th: 'นโยบายความเป็นส่วนตัว',
  },
  {
    link: '/cookie-policy',
    en: 'Cookie Settings',
    th: 'การตั้งค่าคุกกี้',
  },
  {
    link: '/data-subject-right-request',
    en: 'Data Subject Rights Request Form',
    th: 'คำขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล',
  },
];

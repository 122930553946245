import { FC } from 'react';
import { SVGComponent, SVGProps } from '.';

interface Props extends SVGProps {
  mode?: 'light' | 'dark';
}

const Codium: FC<Props> = ({ mode = 'dark' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3805 25.0723H11.8224C11.129 25.0723 10.4884 24.7059 10.1408 24.1127L5.96416 16.9595C5.61831 16.3663 5.61831 15.6335 5.96416 15.0404L10.1408 7.88716C10.4867 7.29397 11.129 6.92759 11.8224 6.92759H20.3787C20.5675 6.92759 20.7422 6.82814 20.8375 6.66588L23.0785 2.82759C23.2814 2.47865 23.0273 2.04248 22.6197 2.04248H8.97094C8.27747 2.04248 7.63694 2.40886 7.28933 3.00205L0.259388 15.0404C-0.0864626 15.6335 -0.0864626 16.3663 0.259388 16.9595L7.28933 28.9978C7.63518 29.591 8.27747 29.9574 8.97094 29.9574H22.6232C23.0308 29.9574 23.2849 29.5212 23.082 29.1723L20.841 25.334C20.7457 25.1717 20.571 25.0723 20.3822 25.0723H20.3805Z"
        fill={mode === 'dark' ? '#0D375B' : '#FFFFFF'}
      />
      <path
        d="M23.996 3.36668L21.7551 7.20498C21.6598 7.36724 21.6598 7.56613 21.7551 7.72838L26.0341 15.056C26.3799 15.6492 26.3799 16.382 26.0341 16.9752L21.7551 24.3029C21.6598 24.4651 21.6598 24.664 21.7551 24.8263L23.996 28.6646C24.199 29.0135 24.7089 29.0135 24.9136 28.6646L31.7388 16.9752C32.0847 16.382 32.0847 15.6492 31.7388 15.056L24.9136 3.36668C24.7107 3.01775 24.2007 3.01775 23.996 3.36668Z"
        fill="#E74342"
      />
      <path
        d="M16.4065 7.97412H11.8222C11.5063 7.97412 11.2152 8.13987 11.0581 8.41029L6.88146 15.5635C6.72441 15.8339 6.72441 16.1654 6.88146 16.4358L11.0581 23.589C11.2152 23.8594 11.5063 24.0252 11.8222 24.0252H16.4065C16.6782 24.0252 16.8476 23.7338 16.7117 23.5018L12.5862 16.4358C12.4292 16.1654 12.4292 15.8339 12.5862 15.5635L16.7117 8.49753C16.8476 8.26548 16.6782 7.97412 16.4065 7.97412Z"
        fill="#35ADCE"
      />
    </svg>
  );
};

export default Codium;

import { FC } from 'react';

export interface SVGProps {
  stroke?: string;
}

export type SVGComponent = FC<SVGProps>;

export { default as Document } from './Document';
export { default as Fingerprint } from './Fingerprint';
export { default as Bookmark } from './Bookmark';
export { default as Briefcase } from './Briefcase';
export { default as Coins } from './Coins';
export { default as Codium } from './Codium';
export { default as Hamburger } from './Hamburger';
export { default as Cross } from './Cross';
export { default as ChevronDown } from './ChevronDown';

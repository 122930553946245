import type { FC } from 'react';
import Image from 'next/image'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  className?: string;
  icon?: string;
}

const Button: FC<Props> = ({ text, className = '', icon, ...rest }) => {
  return (
    <button
      className={`text-white bg-lightBlue hover:bg-lightBlue-700 btnMedium flex justify-center items-center gap-2 ${className}`}
      {...rest}
    >
      {icon && (
        <Image src={icon} width={20} height={20} />
      )}
      {text}
    </button>
  );
};

export default Button;

import { Lang, useLocale } from '@components/Contexts/LocaleContext';
import { Codium, Cross } from '@components/Icons';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import SidebarItem, { SidebarItemProps } from './SidebarItem';

interface Props {
  items: SidebarItemProps[];
  expandSubItem: (label: string) => void;
  closeSidebar: () => void;
  clickLogo: () => void;
}

const Sidebar: FC<Props> = ({
  items,
  closeSidebar,
  clickLogo,
  expandSubItem,
}) => {
  const { lang, setLang } = useLocale();

  const changeLang = (lang: Lang) => {
    setLang(lang);
    closeSidebar();
  };

  return (
    <div
      className={`w-screen h-screen flex flex-col select-none bg-white shadow overflow-y-scroll overflow-x-hidden scrollbar-none`}
    >
      {/* header */}
      <div className="w-full px-4 md:px-6 py-2 flex justify-between items-center">
        <Link href="/">
          <a onClick={clickLogo}>
            <div className="flex justify-center items-center">
              <Image
                src="/images/navbar/codiumDarkFull.png"
                width={140}
                height={40}
                priority={true}
              />
            </div>
          </a>
        </Link>
        <button className="cursor-pointer" onClick={closeSidebar}>
          <Cross stroke="rgb(64,64,64)" />
        </button>
      </div>

      {/* item list */}
      <ul>
        {items.map((item) => (
          <li key={item.label}>
            <SidebarItem
              {...item}
              closeSidebar={closeSidebar}
              expandSubItem={expandSubItem}
            />
          </li>
        ))}
      </ul>

      {/* footer */}
      <div className="mt-12 mb-4 flex justify-center items-center text-neutral-700">
        <button
          className={`flex items-center cursor-pointer px-2 opacity-50 ${
            lang === 'th' && 'opacity-100'
          }`}
          onClick={() => changeLang('th')}
        >
          <h3 className="text-p2Medium">TH</h3>
        </button>

        <div className="w-0.5 h-3.5 bg-neutral-300"></div>

        <button
          className={`flex items-center cursor-pointer px-2 opacity-50 ${
            lang === 'en' && 'opacity-100'
          }`}
          onClick={() => changeLang('en')}
        >
          <h3 className="text-p2Medium">EN</h3>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;

import React from 'react';
import { SVGComponent } from '.';

const Fingerprint: SVGComponent = ({ stroke = '#29ADCE' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7143 11.9332C15.7143 14.5475 14.5191 17.619 10.0001 17.619C9.35368 17.6252 8.70905 17.5509 8.081 17.398M6.40386 16.6542C4.79434 15.5399 4.28577 13.638 4.28577 11.9332V8.09515C4.28577 6.60372 4.90958 5.25706 5.90958 4.30372M7.50196 3.0142C8.26855 2.59739 9.12748 2.37963 10.0001 2.38086C11.6696 2.38086 13.3267 3.06944 14.2858 4.28563M15.4343 5.44753C15.6153 5.97991 15.7143 6.54944 15.7143 7.14277V9.99991"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 15.7142C8.73055 15.079 8.09531 14.1361 8.09531 12.8856V8.09517C8.09531 7.58999 8.29599 7.10551 8.65321 6.7483C9.01042 6.39109 9.4949 6.19041 10.0001 6.19041C10.5052 6.19041 10.9897 6.39109 11.3469 6.7483C11.7042 7.10551 11.9048 7.58999 11.9048 8.09517V11.9333C11.9048 12.5685 12.222 12.8856 12.8572 12.8856C13.4925 12.8856 13.8096 12.5685 13.8096 11.9333V7.68184C13.8096 7.24748 13.7106 6.81884 13.5201 6.4285C12.8229 4.99993 11.6496 4.28564 10.0001 4.28564C8.35055 4.28564 7.17722 4.99993 6.48008 6.4285C6.28955 6.81884 6.19054 7.24748 6.19055 7.68184V12.8571C6.19055 13.8095 6.3496 14.4447 6.66674 14.7618"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.09521V11.9333C10 13.819 10.9524 14.7619 12.8571 14.7619"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Fingerprint;

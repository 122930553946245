import { Button } from '@components/Common';
import { Codium, Hamburger } from '@components/Icons';
import { FC, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { Sidebar } from '@components/Sidebar';
import { animateScroll as scroll } from 'react-scroll';
import Link from 'next/link';
import { useRouter } from 'next/router';
import NavbarOldDesignStyle from './NavbarOldDesignStyle';
import { useTailwindBreakpoint } from '@components/Contexts/TailwindBreakpointContext';
import Image from 'next/image';
import { Lang, useLocale } from '@components/Contexts/LocaleContext';
import { SidebarItemProps } from '@components/Sidebar/SidebarItem';
import NavbarDesktopItem from './NavbarDesktopItem';
import s from './Navbar.module.scss';
import NavbarDesktopSubItem from './NavbarDesktopSubItem';

interface Props {
  forOldDesign?: boolean;
}

const Navbar: FC<Props> = ({ forOldDesign = false }) => {
  const { screen } = useTailwindBreakpoint();
  const { lang, setLang } = useLocale();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [items, setItems] = useState<SidebarItemProps[]>(defaultItems); // items for mobile
  const [desktopItems, setDesktopItems] =
    useState<SidebarItemProps[]>(defaultDesktopItems); // items for desktop
  const [desktopExpandItem, setDesktopExpandItem] =
    useState<SidebarItemProps>(null);
  const SidebarRef = useRef();
  const router = useRouter();
  const animateChevron = useAnimation();

  useEffect(() => {
    const initLang = (localStorage.getItem('lang') ?? 'th') as Lang;
    setLang(initLang);

    setActiveItem();
  }, [router]);

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  const handleClickLogo = () => {
    closeSidebar();
    scroll.scrollToTop();
  };

  const expandSubItem = (label: string) => {
    setItems(
      items.map((item) => {
        let expand = false;
        if (item.label === label) {
          expand = !item.expand;
        }
        return { ...item, expand };
      }),
    );
  };

  const expandDesktopSubItem = (label: string | null) => {
    setDesktopItems(
      desktopItems.map((item) => {
        let expand = false;
        if (item.label === label) {
          expand = !item.expand;
        }
        return {
          ...item,
          active: false, // when click expand clear current active route
          expand,
        };
      }),
    );
  };

  const setActiveItem = () => {
    const newItems = desktopItems.map((item) => {
      let isActive = false;
      if (item?.subItems?.length > 0) {
        isActive = item.subItems
          .filter((s) => s.linkTo !== '/')
          .some((s) => s.linkTo === router.pathname);
      } else {
        isActive = item.linkTo === router.pathname;
      }
      return {
        ...item,
        expand: false, // clear all expand sub items when initial active route
        active: isActive,
      };
    });
    setDesktopItems(newItems);
  };

  useEffect(() => {
    let expandItem = null;
    desktopItems.forEach((item) => {
      if (item?.expand) {
        animateChevron.start({
          rotate: item.expand ? 180 : 0,
          transition: { duration: 0.2 },
        });
        expandItem = item;
      }
    });
    setDesktopExpandItem(expandItem);
  }, [desktopItems]);

  if (screen === 'lg') {
    // DESKTOP
    return (
      <>
        <div className="fixed top-0 left-0 z-50 w-screen px-6 py-0 flex justify-between items-center bg-white shadow select-none">
          {/* Codium logo   */}
          <Link href="/">
            <a onClick={handleClickLogo}>
              <div className="flex justify-center items-center w-[140px] h-[52px]">
                <Image
                  src="/images/navbar/codiumDarkFull.png"
                  width={140}
                  height={40}
                  priority={true}
                />
              </div>
            </a>
          </Link>

          {/* navbar desktop items */}
          <div className="flex space-x-3 items-center">
            <div className="flex items-center">
              {desktopItems.map((item) => (
                <div key={item.label}>
                  {item.subItems?.length > 0 ? (
                    <NavbarDesktopItem
                      {...item}
                      expandSubItem={expandDesktopSubItem}
                    />
                  ) : (
                    <Link href={item.linkTo}>
                      <a onClick={() => {}}>
                        <NavbarDesktopItem {...item} />
                      </a>
                    </Link>
                  )}
                </div>
              ))}
            </div>

            {/* switch lang button */}
            <div className="flex justify-center items-center text-neutral-700">
              <button
                className={`flex items-center cursor-pointer px-2 opacity-50 ${
                  lang === 'th' && 'opacity-100'
                }`}
                onClick={() => setLang('th')}
              >
                <h3 className="text-p3Medium">TH</h3>
              </button>

              <div className="w-0.5 h-3.5 bg-neutral-300"></div>

              <button
                className={`flex items-center cursor-pointer px-2 opacity-50 ${
                  lang === 'en' && 'opacity-100'
                }`}
                onClick={() => setLang('en')}
              >
                <h3 className="text-p3Medium">EN</h3>
              </button>
            </div>

            {/* contact us button */}
            <Button
              id="contact-us"
              text={lang === 'en' ? 'CONTACT US' : 'ติดต่อเรา'}
              style={{ padding: '10px 16px' }}
              className="text-p3Medium min-w-[118px]"
              onClick={() => router.push('/contact-us')}
            />
          </div>
        </div>

        <AnimatePresence>
          {desktopExpandItem && (
            <>
              {/* sub items */}
              <motion.div
                className="fixed top-0 left-0 z-40 w-screen px-[20px] 2xl:px-[89px] pt-[100px] pb-[40px] flex justify-start items-center bg-white shadow select-none rounded-b-[6px]"
                initial={{ y: '-100%' }}
                animate={{ y: 0, transition: { duration: 0.5 } }}
                exit={{ y: '-100%', transition: { duration: 0.5 } }}
                transition={{ ease: 'easeInOut' }}
              >
                <NavbarDesktopSubItem {...desktopExpandItem} />
              </motion.div>

              {/* background blur */}
              <motion.div
                className={`${s.bgBlur} fixed top-0 left-0 z-30 w-screen h-screen`}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.6, delay: 0.3 },
                }}
                exit={{ opacity: 0 }}
                onClick={() => expandDesktopSubItem(null)}
              ></motion.div>
            </>
          )}
        </AnimatePresence>

        {forOldDesign && <NavbarOldDesignStyle />}
      </>
    );
  }

  // MOBILE & TABLET
  return (
    <>
      <div className="fixed top-0 left-0 z-40 w-screen px-4 md:px-6 py-2 flex justify-between items-center bg-white shadow select-none">
        {screen === 'md' ? (
          <Link href="/">
            <a onClick={handleClickLogo}>
              <div className="flex justify-center items-center">
                <Image
                  src="/images/navbar/codiumDarkFull.png"
                  width={140}
                  height={40}
                  priority={true}
                />
              </div>
            </a>
          </Link>
        ) : (
          <Link href="/">
            <a onClick={handleClickLogo}>
              {/* <Codium /> */}
              <div className="flex justify-center items-center">
                <Image
                  src="/images/navbar/codiumDarkFull.png"
                  width={120}
                  height={34}
                  priority={true}
                />
              </div>
            </a>
          </Link>
        )}

        <div className="flex space-x-3 md:space-x-4 items-center">
          <Button
            id="contact-us"
            text={lang === 'en' ? 'CONTACT US' : 'ติดต่อเรา'}
            style={
              forOldDesign
                ? { padding: '5px 10px' }
                : { padding: '0.313rem 0.625rem' }
            }
            onClick={() => router.push('/contact-us')}
          />
          <button
            className="cursor-pointer"
            onClick={() => setOpenSidebar(true)}
          >
            <Hamburger />
          </button>
        </div>

        <AnimatePresence>
          {openSidebar && (
            <motion.div
              ref={SidebarRef}
              className="absolute top-0 left-0 z-50"
              initial={{ x: '100vw' }}
              animate={{ x: 0 }}
              exit={{ x: '100vw' }}
              transition={{ duration: 0.35 }}
            >
              <Sidebar
                items={items}
                closeSidebar={closeSidebar}
                clickLogo={handleClickLogo}
                expandSubItem={expandSubItem}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {forOldDesign && <NavbarOldDesignStyle />}
    </>
  );
};

export default Navbar;

const defaultItems: SidebarItemProps[] = [
  {
    icon: '/images/productServices.svg',
    label: 'Product & Services',
    expand: true,
    subItems: [
      {
        label: 'e-Contract',
        subLabel: 'End-to-end legally binding digital contract.',
        subLabelTH: 'ระบบทำสัญญาดิจิทัล',
        linkTo: '/e-contract',
        icon: '/images/eContract.svg',
      },
      {
        label: 'e-Signature',
        subLabel: 'Electronic signature management system.',
        subLabelTH: 'ระบบจัดการลายมือชื่อดิจิทัล',
        linkTo: '/e-signature',
        icon: '/images/eDigitalSignature.svg',
      },
      {
        label: 'e-Memo',
        subLabel: 'Digital workflow and approval platform.',
        subLabelTH: 'ระบบจัดการเอกสารออนไลน์',
        linkTo: '/e-memo',
        icon: '/images/eMemo.svg',
      },
      {
        label: 'e-Tax Invoice & e-Receipt',
        subLabel: 'All-in-one electronic tax platform.',
        subLabelTH: 'ระบบออกใบกำกับภาษีอิเล็กทรอนิกส์',
        linkTo: '/e-tax',
        icon: '/images/eTax.svg',
      },
    ],
  },
  {
    icon: '/images/cultureCareer.svg',
    label: 'Culture & Career',
    linkTo: '/culture-career',
  },
  {
    icon: '/images/newsfeed.svg',
    label: 'Community',
    subItems: [
      {
        label: 'Blogs',
        subLabel: 'The ultimate enabler is the knowledge in the first place',
        subLabelTH: 'อ่านบทความที่น่าสนใจ',
        linkTo: '/blogs',
        icon: '/images/blogs.svg',
      },
      {
        label: 'Resources',
        subLabel: 'Learning space with educational resources ',
        subLabelTH: 'พื้นที่แห่งการเรียนรู้',
        linkTo: '/resources',
        icon: '/images/resources.svg',
      },
    ],
  },
  {
    icon: '/images/contactUs.svg',
    label: 'Contact us',
    linkTo: '/contact-us',
  },
];

const defaultDesktopItems: SidebarItemProps[] = [
  {
    icon: '/images/home.svg',
    inactiveIcon: '/images/homeGray.svg',
    label: 'Home',
    linkTo: '/',
    active: false,
  },
  {
    icon: '/images/productServices.svg',
    inactiveIcon: '/images/productServicesGray.svg',
    label: 'Product & Services',
    expand: false,
    active: false,
    subItems: [
      {
        label: 'e-Contract',
        subLabel: 'End-to-end legally binding digital contract.',
        subLabelTH: 'ระบบทำสัญญาดิจิทัล',
        linkTo: '/e-contract',
        icon: '/images/eContract.svg',
      },
      {
        label: 'e-Signature',
        subLabel: 'Electronic signature management system.',
        subLabelTH: 'ระบบจัดการลายมือชื่อดิจิทัล',
        linkTo: '/e-signature',
        icon: '/images/eDigitalSignature.svg',
      },
      {
        label: 'e-Memo',
        subLabel: 'Digital workflow and approval platform.',
        subLabelTH: 'ระบบจัดการเอกสารออนไลน์',
        linkTo: '/e-memo',
        icon: '/images/eMemo.svg',
      },
      {
        label: 'e-Tax Invoice & e-Receipt',
        subLabel: 'All-in-one electronic tax platform.',
        subLabelTH: 'ระบบออกใบกำกับภาษีอิเล็กทรอนิกส์',
        linkTo: '/e-tax',
        icon: '/images/eTax.svg',
      },
    ],
  },
  {
    icon: '/images/cultureCareer.svg',
    inactiveIcon: '/images/cultureCareerGray.svg',
    active: false,
    label: 'Culture & Career',
    linkTo: '/culture-career',
  },
  {
    icon: '/images/newsfeed.svg',
    inactiveIcon: '/images/newsfeedGray.svg',
    active: false,
    label: 'Community',
    expand: false,
    subItems: [
      {
        label: 'Blogs',
        subLabel: 'The ultimate enabler is the knowledge in the first place',
        subLabelTH: 'อ่านบทความที่น่าสนใจ',
        linkTo: '/blogs',
        icon: '/images/blogs.svg',
      },
      {
        label: 'Resources',
        subLabel: 'Learning space with educational resources ',
        subLabelTH: 'พื้นที่แห่งการเรียนรู้',
        linkTo: '/resources',
        icon: '/images/resources.svg',
      },
    ],
  },
];

import React from 'react';
import { SVGComponent } from '.';

const Briefcase: SVGComponent = ({ stroke = '#29ADCE' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23814 7.14279H14.7619C15.2671 7.14279 15.7516 7.34347 16.1088 7.70068C16.466 8.05789 16.6667 8.54238 16.6667 9.04755V13.8095C16.6667 14.3146 16.466 14.7991 16.1088 15.1563C15.7516 15.5135 15.2671 15.7142 14.7619 15.7142H5.23814C4.73296 15.7142 4.24848 15.5135 3.89127 15.1563C3.53405 14.7991 3.33337 14.3146 3.33337 13.8095V9.04755C3.33337 8.54238 3.53405 8.05789 3.89127 7.70068C4.24848 7.34347 4.73296 7.14279 5.23814 7.14279V7.14279ZM9.04766 4.28564H10.9524C11.4576 4.28564 11.9421 4.48632 12.2993 4.84354C12.6565 5.20075 12.8572 5.68523 12.8572 6.19041V7.14279H7.1429V6.19041C7.1429 5.68523 7.34358 5.20075 7.70079 4.84354C8.058 4.48632 8.54249 4.28564 9.04766 4.28564Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Briefcase;

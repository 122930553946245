import type { FC, ReactNode } from 'react';
import Image from 'next/image';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  align?: 'center' | 'left' | 'right';
}

const Title: FC<Props> = ({
  children,
  className = '',
  align = 'center'
}) => {
  return (
    <h2
      className={`inline-block gradient-head-large !text-h5Bold text-center !leading-10 !md:text-h2Bold !md:leading-[3.5rem] text-${align} ${className}`}
    >
      {children}
    </h2>
  );
};

export default Title;

import { SidebarItemProps } from '@components/Sidebar/SidebarItem';
import { FC, useEffect } from 'react';
import Image from 'next/image';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { ChevronDown } from '@components/Icons';

const NavbarDesktopItem: FC<SidebarItemProps> = ({
  icon,
  inactiveIcon,
  label,
  expand,
  active = false,
  expandSubItem = (l) => {},
  subItems = [],
}) => {
  const animateChevron = useAnimation();

  useEffect(() => {
    animateChevron.start({
      rotate: expand ? 180 : 0,
      transition: { duration: 0.2 },
    });
  }, [expand]);

  return (
    <div
      className={`relative h-full w-fit flex justify-center items-center py-5 px-3 xxl:px-5 cursor-pointer hover:bg-lightBlue-50`}
      onClick={() => expandSubItem(label)}
    >
      <div className="w-[20px] h-[20px] mr-1 xxl:mr-3">
        <Image
          src={active || expand ? icon : inactiveIcon}
          width={20}
          height={20}
          layout="fixed"
        />
      </div>
      <h3 className=" text-p3Medium xxl:text-p2Medium text-neutral-700 mr-1 xxl:mr-3">
        {label}
      </h3>
      {subItems?.length > 0 && (
        <motion.div className="h-fit w-fit" animate={animateChevron}>
          <ChevronDown stroke="rgb(64,64,64)" />
        </motion.div>
      )}

      <AnimatePresence>
        {(active || expand) && (
          <motion.div
            className="absolute inset-x-0 bottom-0 h-[4px] bg-lightBlue"
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{ width: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          ></motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavbarDesktopItem;

export interface SubContent {
  id: number;
  title: string;
  description: string;
  titleTH: string;
  descriptionTH: string;
  type: ContentType;
  animations: string;
  name: string;
  bullet: boolean;
  textColor: string;
  image: Image;
  backgroundAnimation?: string;
  buttonText?: string;
}

export interface ContentItem {
  id: number;
  title: string;
  description: string;
  titleTH: string;
  descriptionTH: string;
  inspired?: string;
  type: ContentType;
  animations: string;
  name: string;
  bullet: boolean;
  textColor: string;
  subContent?: SubContent[];
  published_at?: Date;
  image?: Image;
  videoCover?: Image;
  imageMobile?: Image;
  backgroundAnimation?: string;
  form?: Forms[];
}

export interface Image {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: any;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: any;
  related: string;
  created_by: string;
  updated_by: string;
}

export interface Forms {
  id: string;
  inputType: string;
  placeHolder: string;
  label: string;
  required: boolean;
  name: string;
}

export type ContentType =
  | 'image'
  | 'headLine'
  | 'subHeadline'
  | 'title'
  | 'button'
  | 'description';

export type Animation =
  | 'fadeup'
  | 'fadeDown'
  | 'fadeRight'
  | 'fadeLeft'
  | 'fadeUpRight'
  | 'fadeUpLeft'
  | 'fadeDownRight'
  | 'fadeDownLeft'
  | 'flipLeft'
  | 'flipRight'
  | 'flipUp'
  | 'flipDown'
  | 'zoomIn'
  | 'zoomInUp'
  | 'zoomInDown'
  | 'zoomInLeft'
  | 'zoomInRight'
  | 'zoomOut'
  | 'zoomOutUp'
  | 'zoomOutDown'
  | 'zoomOutRight'
  | 'zoomOutLeft';

export type Colors = 'danger' | 'primary' | 'secondary' | 'dark';

export const be = process.env.NEXT_PUBLIC_BE
  ? process.env.NEXT_PUBLIC_BE
  : 'https://new-dev.codium.co';

export const strapi = process.env.NEXT_PUBLIC_STRAPI
  ? process.env.NEXT_PUBLIC_STRAPI
  : 'https://new-dev.codium.co';

export const domain = process.env.NEXT_PUBLIC_DOMAIN
  ? process.env.NEXT_PUBLIC_DOMAIN
  : 'www.codium.co';

export const webUrl = process.env.NEXT_PUBLIC_URL
  ? process.env.NEXT_PUBLIC_URL
  : 'https://www.codium.co';

export const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

// export const domain = process.env.NEXT_PUBLIC_DOMAIN ? process.env.NEXT_PUBLIC_DOMAIN : 'localhost:3000';
export const blockGoogleSearch =
  process.env.NEXT_PUBLIC_BLOCK_GOOGLE_SEARCH === 'true';

export const isDev = process.env.NEXT_PUBLIC_IS_DEV
  ? process.env.NEXT_PUBLIC_IS_DEV === 'true'
  : false;

export interface IndexObject {
  id: number;
  title: string;
  name: string;
  type: string;
  recommend?: any;
  theme?: string;
  published_at: Date;
  created_at: Date;
  updated_at: Date;
  description?: any;
  detail?: string;
  detailTH?: string;
  animation?: any;
  bullet?: any;
  textColor?: any;
  link?: any;
  subContent: SubContent[];
}

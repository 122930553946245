import { SVGComponent } from '.';

const Bookmark: SVGComponent = ({stroke = '#29ADCE'}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19053 3.33325H13.8096C14.3148 3.33325 14.7992 3.53393 15.1564 3.89114C15.5137 4.24836 15.7143 4.73284 15.7143 5.23801V14.7618C15.7143 15.267 15.5137 15.7515 15.1564 16.1087C14.7992 16.4659 14.3148 16.6666 13.8096 16.6666H6.19053C5.68535 16.6666 5.20087 16.4659 4.84366 16.1087C4.48645 15.7515 4.28577 15.267 4.28577 14.7618V5.23801C4.28577 4.73284 4.48645 4.24836 4.84366 3.89114C5.20087 3.53393 5.68535 3.33325 6.19053 3.33325V3.33325Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14294 3.33325H10.9525V8.10659L9.04771 6.19039L7.14294 8.10659V3.33325Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bookmark;

import { useLocale } from '@components/Contexts/LocaleContext';
import { useTailwindBreakpoint } from '@components/Contexts/TailwindBreakpointContext';
import { SidebarItemProps } from '@components/Sidebar/SidebarItem';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC, useEffect, useRef, useState } from 'react';

const NavbarDesktopSubItem: FC<SidebarItemProps> = ({
  label,
  subItems = [],
}) => {
  const { lang } = useLocale();
  const { screenWidth } = useTailwindBreakpoint();
  const [maxHeight, setMaxHeight] = useState(null);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    setMaxHeight(null);
  }, []);

  useEffect(() => {
    setMaxHeight(null);
  }, [subItems, lang, label, screenWidth]);

  useEffect(() => {
    if (!maxHeight) {
      setMaxHeight(ref.current?.clientHeight);
    }
  }, [maxHeight]);

  return (
    <div className="relative flex flex-col items-start w-full space-y-[18px]">
      <div
        ref={ref}
        className="w-full flex justify-start items-center space-x-[20px]"
      >
        {subItems.map((item) => (
          <Link key={item.label} href={item.linkTo}>
            <a
              className={`w-1/4 flex border-lightBlue-50 space-x-[16px] border-2 px-[20px] py-[16px] rounded-[6px] hover:bg-lightBlue-50`}
              style={{ height: maxHeight }}
            >
              {item?.icon && (
                <div className="w-[24px]">
                  <Image
                    src={item.icon}
                    width={24}
                    height={24}
                    layout="fixed"
                  />
                </div>
              )}
              <div className="flex flex-col justify-start">
                <h3 className=" text-p2Semibold text-neutral-700">
                  {item.label}
                </h3>
                <span className=" text-p3Regular text-neutral-500">
                  {lang === 'en' ? item.subLabel : item.subLabelTH}
                </span>
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NavbarDesktopSubItem;

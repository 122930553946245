import React from 'react';

/**
 * For old design It's hard to use REM unit, so I change tailwind class unit to be PX
 */
const NavbarOldDesignStyle = () => {
  return (
    <style jsx global>{`
      li {
        list-style-type: none;
      }
      .btnMedium {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 6px;
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 16px;
        line-height: normal;
      }
      .text-p2Medium {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .text-p3Semibold {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
      }
      .text-p3Regular {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
      .text-p2Semibold {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
      .p-5 {
        padding: 20px;
      }
      .px-4 {
        padding-left: 16px;
        padding-right: 16px;
      }
      .py-2 {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .py-4 {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .py-5 {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .px-3 {
        padding-left: 12px;
        padding-right: 12px;
      }
      .px-6 {
        padding-left: 24px;
        padding-right: 24px;
      }
      .mr-1 {
        margin-right: 4px;
      }
      .mr-3 {
        margin-right: 12px;
      }
      .space-x-3 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0 !important;
        margin-right: calc(12px * var(--tw-space-x-reverse)) !important;
        margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse))) !important;
      }
      @media (min-width: 768px) {
        .md\:px-6 {
          padding-left: 24px !important;
          padding-right: 24px !important;
        }
        .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
          --tw-space-x-reverse: 0 !important;
          margin-right: calc(16px * var(--tw-space-x-reverse)) !important;
          margin-left: calc(16px * calc(1 - var(--tw-space-x-reverse))) !important;
        }
        .md\:pr-6 {
          padding-right: 24px !important;
        }
        .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
          --tw-space-y-reverse: 0;
          margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
          margin-bottom: calc(16px * var(--tw-space-y-reverse));
        }
      }
      @media (min-width: 1440px) {
        .xxl\:text-p2Medium {
          font-family: 'BaiJamjuree', 'Helvetica', sans-serif !important;
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 500 !important;
        }
        .xxl\:mr-3 {
          margin-right: 12px !important;
        }
        .xxl\:px-5 {
          padding-left: 20px !important;
          padding-right: 20px !important;
        }
      }
    `}</style>
  );
};

export default NavbarOldDesignStyle;

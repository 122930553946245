import Head from 'next/head'
import Script from 'next/script';
import { FC } from 'react';
import { webUrl, domain, blockGoogleSearch, isDev } from 'models/conent';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { getPathFromUrl } from 'utils/url';

interface IHeader {
    content: string;
    title: string;
    image: string;
    url?: string;
}

interface IProps {
    content?: IHeader;
}
const Header: FC<IProps> = (props) => {
    const router = useRouter();
    const desc = 'ผู้ให้บริการ Full-service Digital Transformation เปลี่ยนการทำงานจากกระดาษสู่ดิจิทัล ผู้เชี่ยวชาญด้าน Digital Signature ที่กว่า 100 บริษัทที่ไว้วางใจ';
    const image = webUrl + '/images/CodiumOgImage.jpg';
    const title = 'CODIUM เปลี่ยนการทำงานจากกระดาษสู่ดิจิทัล';
    const pageUrl = webUrl + getPathFromUrl(router.asPath);

    return <>
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" type="image/png" href="/images/CODIUM.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta charSet="utf-8" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="facebook-domain-verification" content="tnaexja89roj9zcv6qjoz6h5n6naxx" />
            <meta name="google-site-verification" content="Jp479_uwiyZdZ2wLqR4LPesF7e9zfbCdOEWZpzpXvIU" />
        </Head>

        <NextSeo
            noindex={blockGoogleSearch}
            title={props.content?.title ? props.content?.title : title}
            description={props.content?.content ? props.content?.content : desc}
            canonical={pageUrl}
            openGraph={{
                type: 'website',
                title: props.content?.title ? props.content?.title : title,
                description: props.content?.content ? props.content?.content : desc,
                url: pageUrl,
                site_name: domain,
                images: [
                    {
                        url: `${props.content?.image ? `${props.content.image}` : image}`
                    }
                ]
            }}
            twitter={{
                cardType: 'summary_large_image',
                site: '@CodiumOfficial',
                handle: '@CodiumOfficial'
            }}
        />
        
        <div className="">
            <Script id="smoothscroll.min.js" src="https://unpkg.com/smoothscroll-polyfill/dist/smoothscroll.min.js"></Script>
            <Script id="smoothscroll-anchor-polyfill" src="https://unpkg.com/smoothscroll-anchor-polyfill"></Script>

            {/* Google Tag Manager */}
            <Script
                id="gtm-script"
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-N3QX6HR');`
                }}
            />
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N3QX6HR"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            ></noscript>

            {/* End Google Tag Manager (noscript) */}

            <Script
                id="facebook-script"
                dangerouslySetInnerHTML={{
                    __html: `!function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '796368261118863');
                        fbq('track', 'PageView');`
                }}
            />
            <noscript dangerouslySetInnerHTML={{
                __html: `<img height="1" width="1" style="display:none" loading="lazy"
                    alt="CODIUM ผู้ให้บริการซอฟต์แวร์และเทคโนโลยีอย่างชาญฉลาดในยุคดิจิทัล"
                    src="https://www.facebook.com/tr?id=796368261118863&ev=PageView&noscript=1"
                    />`,
            }}>
            </noscript>

            <Script
              id="schema-script"
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "Organization",
                      address: {
                          "@type": "PostalAddress",
                          "addressCountry": "Thailand",
                          "addressRegion": "Bangkok",
                          "postalCode": "10330",
                          "streetAddress": "Rama 1 Road, Wang Mai",
                          "address": "Siam@Siam Hotel Building, 7th Floor 865 Rama 1 Road, Wang Mai, Patumwan Bangkok 10330, Thailand"
                      },
                      name: "Codium",
                      telephone: "02 612 3838",
                      brand: "E-Memo, E-Digital Signature, E-Tax Invoice, E-Receipt"
                  })
              }}
            />

           
            {isDev ? (
                <Script strategy="beforeInteractive" data-cfasync="false" type="text/javascript" src="https://cdm.pdpa-codium-uat.com/banner/cdm-cookie-banner.min.js" data-endpoint="https://cdm.pdpa-codium-uat.com" data-site-id="274f9b5b-1d65-41a1-a55c-db51dfce6ae6"></Script>
            ) : (
                <Script strategy="beforeInteractive" data-cfasync="false" type="text/javascript" src="https://cdm.pdpa-codium.com/banner/cdm-cookie-banner.min.js" data-endpoint="https://cdm.pdpa-codium.com" data-site-id="8154b692-1669-4090-b5f5-5dbc0b2e2a69"></Script>
            )}

            {/* HotJar */}
            {isDev ? (
                <Script
                    id='hotjar-script'
                    strategy='afterInteractive'
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:3669197,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `
                    }}
                />
            ) : (
                <Script
                    id='hotjar-script'
                    strategy='afterInteractive'
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:3197886,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `
                    }}
                />
            )}
            
        </div>
    </>
}
export default Header;

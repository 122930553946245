import React from 'react';

const FooterOldDesignStyle = () => {
  return (
    <style jsx global>{`
      .btnMedium {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 6px;
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 16px;
        line-height: normal;
      }
      .text-p2Medium {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .text-p3Medium {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
      .text-p3Regular {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
      .text-p4Regular {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }
      .text-h6Medium {
        font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
      }
      .px-4 {
        padding-left: 16px;
        padding-right: 16px;
      }
      .px-10 {
        padding-left: 40px;
        padding-right: 40px;
      }
      .px-16 {
        padding-left: 64px;
        padding-right: 64px;
      }
      .py-2 {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .py-4 {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .py-5 {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .py-7 {
        padding-top: 28px;
        padding-bottom: 28px;
      }
      .mr-3 {
        margin-right: 12px;
      }
      .mt-4 {
        margin-top: 16px;
      }
      .mb-7 {
        margin-bottom: 28px;
      }
      .my-7 {
        margin-top: 28px;
        margin-bottom: 28px;
      }

      .gap-2 {
        gap: 8px;
      }
      .gap-3 {
        gap: 12px;
      }
      .gap-5 {
        gap: 20px;
      }

      .rounded-md {
        border-radius: 6px;
      }
      @media (min-width: 768px) {
        .md\:px-4 {
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
        .md\:text-p2Medium {
          font-family: 'BaiJamjuree', 'Helvetica', sans-serif;
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 500 !important;
        }
      }
      @media (min-width: 1024px) {
        .lg\:px-16 {
          padding-left: 64px !important;
          padding-right: 64px !important;
        }
      }
    `}</style>
  );
};

export default FooterOldDesignStyle;

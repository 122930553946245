import { SVGComponent } from '.';

const Document: SVGComponent = ({ stroke = '#29ADCE' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.19053 9.99992H10.9524M6.19053 11.9047H12.8572M6.19053 13.8094H9.04767M15.7143 14.7618V8.09516L10.9524 3.33325H6.19053C5.68535 3.33325 5.20087 3.53393 4.84366 3.89114C4.48645 4.24836 4.28577 4.73284 4.28577 5.23801V14.7618C4.28577 15.267 4.48645 15.7515 4.84366 16.1087C5.20087 16.4659 5.68535 16.6666 6.19053 16.6666H13.8096C14.3148 16.6666 14.7992 16.4659 15.1564 16.1087C15.5137 15.7515 15.7143 15.267 15.7143 14.7618V14.7618Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9524 3.33325V6.19039C10.9524 6.69557 11.1531 7.18005 11.5103 7.53726C11.8675 7.89448 12.352 8.09516 12.8572 8.09516H15.7143"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Document;

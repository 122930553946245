import { useLocale } from '@components/Contexts/LocaleContext';
import { useTailwindBreakpoint } from '@components/Contexts/TailwindBreakpointContext';
import { ChevronDown } from '@components/Icons';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import s from './Sidebar.module.scss';

export interface SubItem {
  label: string;
  subLabel: string;
  subLabelTH: string;
  linkTo: string;
  icon?: string;
}

export interface SidebarItemProps {
  icon: string;
  label: string;
  linkTo?: string;
  subItems?: SubItem[];
  expand?: boolean;
  active?: boolean;
  inactiveIcon?: string;
  expandSubItem?: (label: string) => void;
  closeSidebar?: () => void;
}

const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  label,
  linkTo,
  closeSidebar,
  expand,
  expandSubItem = (l) => {},
  subItems = [],
}) => {
  const [hasSubItem, setHasSubItem] = useState<boolean>(false);
  const animateChevron = useAnimation();
  const { lang } = useLocale();
  const { screen } = useTailwindBreakpoint();

  useEffect(() => {
    setHasSubItem(subItems.length > 0);
  }, [subItems]);

  useEffect(() => {
    animateChevron.start({
      rotate: expand ? 180 : 0,
      transition: { duration: 0.2 },
    });
  }, [expand]);

  const itemElement = (
    <div
      className={`px-4 md:px-6 py-5 text-neutral-700 cursor-pointer flex justify-between`}
      onClick={() => expandSubItem(label)}
    >
      <div className="flex items-center">
        <div className="w-[20px] h-[20px] mr-3">
          <Image src={icon} width={20} height={20} layout="fixed" />
        </div>

        <div className="flex flex-col">
          <h1 className="text-p2Medium">{label}</h1>
        </div>
      </div>

      {hasSubItem && (
        <motion.div className="h-fit w-fit" animate={animateChevron}>
          <ChevronDown stroke="rgb(64,64,64)" />
        </motion.div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col">
      {/* item */}
      {linkTo ? (
        <Link href={linkTo}>
          <a onClick={closeSidebar}>{itemElement}</a>
        </Link>
      ) : (
        itemElement
      )}

      {/* subitems */}
      <AnimatePresence>
        {expand && hasSubItem && (
          <motion.ul
            className="flex-grow md:pl-14 md:pr-6 md:space-y-4"
            initial={{ height: 0 }}
            animate={{ height: 'fit-content' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {subItems.map((item, i) => (
              <motion.li
                key={item.label}
                initial="initial"
                animate="animate"
                exit="exit"
                custom={i}
                variants={{
                  initial: { opacity: 0, x: '10%' },
                  animate: (i: any) => ({
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.25 + i * 0.08, duration: 0.15 },
                  }),
                  exit: {
                    opacity: 0,
                    x: '10%',
                    transition: { duration: 0.15 },
                  },
                }}
              >
                {screen === 'md' ? (
                  // for tablet
                  <Link key={item.label} href={item.linkTo}>
                    <a
                      onClick={closeSidebar}
                      className={`flex border-lightBlue-50 space-x-[16px] border-2 w-full px-[20px] py-[16px] rounded-[6px] hover:bg-lightBlue-50`}
                    >
                      {item?.icon && (
                        <div className="">
                          <Image src={item.icon} width={24} height={24} />
                        </div>
                      )}
                      <div className="flex flex-col justify-start">
                        <h3 className=" text-p2Semibold text-neutral-700">
                          {item.label}
                        </h3>
                        <span className=" text-p3Regular text-neutral-500">
                          {lang === 'en' ? item.subLabel : item.subLabelTH}
                        </span>
                      </div>
                    </a>
                  </Link>
                ) : (
                  // for mobile
                  <Link href={item.linkTo}>
                    <a
                      onClick={closeSidebar}
                      className={`px-4 md:px-6 py-4 text-neutral-700 flex cursor-pointer`}
                    >
                      <div className="w-[20px] h-[20px] mr-3"></div>
                      <div className="flex flex-col">
                        <h2 className="text-p3Semibold">{item.label}</h2>
                        <span className="text-p3Regular text-neutral-500">
                          {lang === 'en' ? item.subLabel : item.subLabelTH}
                        </span>
                      </div>
                    </a>
                  </Link>
                )}
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SidebarItem;

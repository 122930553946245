import { SVGComponent } from '.';

const Coins: SVGComponent = ({ stroke = '#29ADCE' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6191 10.9524V13.8095C17.6191 15.0476 14.6343 16.6667 10.9524 16.6667C7.27053 16.6667 4.28577 15.0476 4.28577 13.8095V11.4286"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.56567 11.6752C5.38948 12.7686 7.9371 13.7943 10.9523 13.7943C14.6342 13.7943 17.619 12.2648 17.619 10.9505C17.619 10.2124 16.679 9.4038 15.2038 8.83618"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7619 6.19055V9.04769C14.7619 10.2858 11.7772 11.9048 8.09526 11.9048C4.41335 11.9048 1.42859 10.2858 1.42859 9.04769V6.19055"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.09526 9.03242C11.7772 9.03242 14.7619 7.5029 14.7619 6.18861C14.7619 4.87337 11.7772 3.33337 8.09526 3.33337C4.41335 3.33337 1.42859 4.87337 1.42859 6.18861C1.42859 7.5029 4.41335 9.03242 8.09526 9.03242Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Coins;

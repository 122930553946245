import React from 'react';

const Hamburger = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00305 10H25.0001"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15.999H24.9925"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00305 21.998H24.9926"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Hamburger;
